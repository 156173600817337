import axios from 'axios';

export const getUserCountry = async () => {
  try {
    // Check if country is already stored
    const storedCountry = localStorage.getItem('userCountry');
    if (storedCountry) {
      return storedCountry;
    }

    // Get country from IP API
    const response = await axios.get("https://ipapi.co/json/");
    if (response.data) {
      const countryCode = response.data.country_code;
      localStorage.setItem('userCountry', countryCode);
      return countryCode;
    }
    return 'US'; // Default to US if API fails
  } catch (error) {
    console.error('Error getting user country:', error);
    return 'US'; // Default to US if API fails
  }
};

export const getCurrencySymbol = (countryCode) => {
  switch (countryCode) {
    case 'IN':
      return '₹';
    case 'AU':
      return 'AU$';
    case 'CA':
      return 'CAD$';
    case 'UK':
      return '€';
    case 'BR':
      return 'R$';
    case 'MX':
      return 'MX$';
    default:
      return '$';
  }
};

// Conversion rates (you can update these as needed)
const CONVERSION_RATES = {
  'IN': 83.12,  // 1 USD = 83.12 INR
  'AU': 1.52,   // 1 USD = 1.52 AUD
  'CA': 1.35,   // 1 USD = 1.35 CAD
  'UK': 0.92,   // 1 USD = 0.92 EUR
  'US': 1,      // 1 USD = 1 USD
  'BR': 4.97,   // 1 USD = 4.97 BRL
  'MX': 16.95   // 1 USD = 16.95 MXN
};

export const getPrice = (product, countryCode) => {
  if (!product) return '0.00';
  
  // Get the base price (individual_price)
  const basePrice = product.individual_price || product.productList?.individual_price || '0.00';
  
  // Convert the price based on country
  const conversionRate = CONVERSION_RATES[countryCode] || 1;
  const convertedPrice = parseFloat(basePrice) * conversionRate;
  
  return convertedPrice.toFixed(2);
};

export const formatPrice = (price, countryCode) => {
  if (!price || isNaN(price)) return '0.00';
  const symbol = getCurrencySymbol(countryCode);
  return `${symbol}${parseFloat(price).toFixed(2)}`;
}; 