import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import AdminPanel from './page/admin/page/AdminPanel';
import ClientPanel from './ClientPanel .jsx';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<ClientPanel />} />
        <Route path="/admin/*" element={<AdminPanel />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
